/*
 *   Videos Reducer
 */

import { RECEIVE_ALL_VIDEOS } from "../../actions/types";

export default function(videos = {}, action) {
  switch (action.type) {
    case RECEIVE_ALL_VIDEOS:
      return action.payload;

    default:
      return videos;
  }
}
