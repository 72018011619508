import React, { Component } from "react";
import Post from "./posts/Post";
import SideBar from "./SideBar";

class VideoListTwo extends Component {
  render() {
    const VideoPost1 = {
      format: "format-standard",
      title: "Spearfishing in Paradise",
      videoUploadDate: "3 days",
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };
    return (
      <section className="video-contents category-sorting column-3">
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                <h1 className="section-title">Video List Two</h1>
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <Post post={VideoPost1} />
                  </div>
                </div>

                <div className="btn-container text-center clearfix">
                  <a href="/" className="btn read-more">
                    Load more
                  </a>
                </div>
              </div>

              <div className="col-sm-4">
                <SideBar />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VideoListTwo;
