import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";

import Header from "./components/header/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import "./App.css";

import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import store from "./store";
import ComingSoon from "./components/comingsoon/ComingSoon";
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

function ComingSoonApp() {
  const comingSoon = false;
  if (comingSoon) {
    return <ComingSoon />;
  }
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Header />
          <Main />
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

const App = () => <ComingSoonApp />;

export default App;
