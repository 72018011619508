import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import logo from "../../images/spearchannel_logo_bw.png";

class HeaderNavigation extends Component {
  state = {
    showSearch: false
  };
  renderProfileNavItem() {
    const { isAuthenticated } = this.props.auth;

    if (isAuthenticated) {
      return (
        <li className="menu-item">
          <NavLink activeclassname="active" to="/profile/home">
            Profile
          </NavLink>
        </li>
      );
    }
  }

  renderSearch = () => {
    return (
      <div className="col-sm-4">
        <form className="search-form" method="get" action="#">
          <input
            name="s"
            type="text"
            className="search-input"
            size="20"
            maxLength="20"
            placeholder="Search Here ...."
            required=""
          ></input>
          <input type="submit" name="submit" className="search-submit"></input>
        </form>
      </div>
    );
  };

  render() {
    return (
      <div className="header-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav className="navbar navbar-default">
                <div className="navbar-header visible-xs">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#menu"
                    aria-expanded="false"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <a className="navbar-brand" href="/">
                    <img src={logo} alt="Logo"></img>
                  </a>
                </div>
                <div
                  id="menu"
                  className="main-menu collapse navbar-collapse pull-left"
                >
                  <ul className="nav navbar-nav">
                    <li className="menu-item">
                      <NavLink  to="/" exact="true">
                        Home
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink to="/videos">
                        Videos
                      </NavLink>
                      <ul className="sub-menu children"></ul>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <NavLink to="/blog">
                        Blog
                      </NavLink>
                      <ul className="sub-menu children">
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/podcasts">Podcasts</Link>
                        </li>
                        <li>
                          <Link to="/hawaiiSkinDiver">Hawaii Skin Diver</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <NavLink to="/quicksubmit/userinfo">
                        Submit Content
                      </NavLink>
                    </li>
                    {this.renderProfileNavItem()}
                  </ul>
                </div>
              </nav>
            </div>
            {this.state.showSearch && this.renderSearch()}
          </div>
        </div>
      </div>
    );
  }
}

HeaderNavigation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(HeaderNavigation);
