import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';
import InstagramWidget from './widgets/InstagramWidget';
import FacebookWidget from './widgets/FacebookWidget';
import LatestPostWidget from './widgets/LatestPostWidget';
import DailyMostViewed from './widgets/DailyMostViewed';
import BannerAdWidget from './ads/BannerAdWidget';
import SearchWidget from './widgets/SearchWidget';
import SocialWidget from './widgets/SocialWidget';
import SubmitVideoWidget from './widgets/SubmitVideoWidget';

class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      showReply: false,
      hideDailyMostViewed: false,
      hideFacebookWidget: false,
      hideInstagramWidget: false,
      hideLatestPostWidget: false,
      hideSearchWidget: true,
      hideSocialWidget: true,
      hideSubmitVideoWidget: false,
    };
  }

  render() {
    return (
      <aside className="sidebar">
        {!this.state.hideLatestPostWidget && <LatestPostWidget />}
        {!this.state.hideBannerAdWidget && <BannerAdWidget />}
        {!this.state.hideDailyMostViewed && <DailyMostViewed />}
        {!this.state.hideInstagramWidget && <InstagramWidget />}
        {!this.state.hideFacebookWidget && <FacebookWidget />}
        {!this.state.hideSearchWidget && <SearchWidget />}
        {!this.state.hideSocialWidget && <SocialWidget />}
        {!this.state.hideSubmitVideoWidget && <SubmitVideoWidget />}
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  hideLatestPostWidget: true,
  hideDailyMostViewed: true,
  hideInstagramWidget: true,
  hideFacebookWidget: true,
})
// const { visibilityFilter } = state;
// const todos = getTodosByVisibilityFilter(state, visibilityFilter);
// return { todos };
//   const allTodos = getTodos(state);
//   return {
//     todos:
//       visibilityFilter === VISIBILITY_FILTERS.ALL
//         ? allTodos
//         : visibilityFilter === VISIBILITY_FILTERS.COMPLETED
//           ? allTodos.filter(todo => todo.completed)
//           : allTodos.filter(todo => !todo.completed)
//   };
;

export default connect(mapStateToProps)(SideBar);
