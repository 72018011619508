/* eslint-disable linebreak-style */
import React from 'react';
import { Link } from 'react-router-dom';
import PostContent from './PostContent';

const Post = (props) => {
  const postData = props.post;
  const defaultImg = 'https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=960&mh=540';
  console.log('rendering post: ', postData);
  let thumbnailImg = `https://i.ytimg.com/vi/${postData.youtube_id}/hqdefault.jpg`;

  if (!postData.youtube_id || postData.youtube_id.length == 0) {
    thumbnailImg = defaultImg;
  }
  const renderImageThumbnail = () => (
    <div className="entry-thumbnail">
      <img
        src={thumbnailImg}
        alt="Entry Thumbnail"
      />
    </div>
  );
  // format-video
  const renderVideoThumbnail = () => (
    <div className="entry-thumbnail">
      <img
        src={thumbnailImg}
        alt="Item Thumbnail"
      />
      <a className="iframe" href="https://vimeo.com/203703152">
        <i className="fa fa-play" />
      </a>
    </div>
  );

  // form type-post
  const renderTypePost = () => {
    if (postData.typePostVideo === true) {
      renderVideoThumbnail();
    } else {
      renderImageThumbnail();
    }
  };

  // format-audio
  const renderAudioThumbnail = () => (
    <div className="entry-thumbnail">
      <iframe
        className="post-audio"
        title="PostAudioTitle"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/161730338&amp;"
      />
    </div>
  );
  // format-link
  const renderFormatLinkThumbnail = () => (
    <div className="entry-thumbnail">
      <span className="link-title">Florida Spearfishing</span>
      <a href="http://flspearfishing.com">flspearfishing.com</a>
    </div>
  );

  // format-gallery
  const renderImageGalleryThumbnail = () => (
    <div className="entry-thumbnail">
      <div id="post-slider" className="post-slider carousel slide">
        <div className="carousel-inner">
          <div className="item active">
            <img
              src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
              alt="Entry Thumbnail"
            />
          </div>
          <div className="item">
            <img
              src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
              alt="Entry Thumbnail"
            />
          </div>
          <div className="item">
            <img
              src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
              alt="Entry Thumbnail"
            />
          </div>
        </div>
        <a
          className="left carousel-control"
          href="#post-slider"
          role="button"
          data-slide="prev"
        >
          <i className="fa fa-angle-left fa-6" aria-hidden="true" />
        </a>

        <a
          className="right carousel-control"
          href="#post-slider"
          role="button"
          data-slide="next"
        >
          <i className="fa fa-angle-right fa-6" aria-hidden="true" />
        </a>
      </div>
    </div>
  );

  /* const renderPlayListItemThumbnail = () => {
    return (
      <div className="entry-thumbnail">
        <img
          src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=960&mh=540"
          alt="Entry Thumbnail"
        ></img>
        <a href="https://vimeo.com/203743018" className="iframe">
          <span className="play-video">
            <i className="fa fa-play-circle-o"></i>
          </span>
        </a>
      </div>
    );
  }; */

  const renderMediaThumbNail = () => (
    <div className="entry-thumbnail media-left">
      <img
        src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1300&mh=562"
        alt="Entry Thumbnail"
      />
    </div>
  );

  const renderThumbnail = () => {
    const { post } = props;
    if (post.format === 'format-gallery') {
      return renderImageGalleryThumbnail();
    } if (post.format === 'format-link') {
      return renderFormatLinkThumbnail();
    } if (post.format === 'format-standard') {
      return renderImageThumbnail();
    } if (post.format === 'format-audio') {
      return renderAudioThumbnail();
    } if (post.format === 'format-video') {
      return renderVideoThumbnail();
    } if (
      post.format === 'type-post'
      && post.mediaPost
      && post.postPlaylist
    ) {
      return (
        <div className="entry-thumbnail media-left">
          <img
            src={thumbnailImg}
            alt="Entry Thumbnail"
          />
          <span className="post-count">
            <a href="/">
              <span className="count">19</span>
              {' '}
              videos
            </a>
          </span>
        </div>
      );
    } if (post.format === 'type-post' && post.typePostVideo) {
      return renderTypePost();
    } if (
      post.format === 'type-post'
      && post.mediaPost
      && !post.postPlaylist
    ) {
      return renderMediaThumbNail();
    } if (
      post.format === 'type-post'
      && !post.videoPlaylistItem
      && !post.mediaPost
    ) {
      return renderImageThumbnail();
    } if (post.format === 'type-post' && post.videoPlaylistItem) {
      return (
        <div className="entry-thumbnail">
          <img
            src={thumbnailImg}
            alt="Entry Thumbnail"
          />
          <a href="https://vimeo.com/203743018" className="iframe">
            <span className="play-video">
              <i className="fa fa-play-circle-o" />
            </span>
          </a>
        </div>
      );
    }
  };

  const renderProfileVideoThumbnail = (post) => (
    <article className="post type-post col-sm-4">
      <div className="entry-thumbnail">
        <img
          src={thumbnailImg}
          alt="Item Thumbnail"
        />
        <a href="https://vimeo.com/201203170" className="iframe">
          <span className="play-video">
            <i className="fa fa-play-circle-o" />
          </span>
        </a>
      </div>
      <div className="entry-content">
        <h2 className="entry-title">
          <Link to={`/post/${post.id}`}>{post.title}</Link>
        </h2>
        <div className="entry-meta">
          <span>
            <i className="fa fa-clock-o" />
            {' '}
            <time dateTime="PT5M">5 months</time>
          </span>
          <span>
            <a href="/">
              <i className="fa fa-comment-o" />
              {' '}
              <span className="count">351</span>
            </a>
          </span>
          <span>
            <i className="fa fa-eye" />
            {' '}
            <span className="count">17,989</span>
          </span>
        </div>

        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
          cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum. Sed ut perspiciatis unde omnis iste natus error
          sit voluptatem
        </p>
      </div>
    </article>
  );

  const { post } = props;
  if (post.type === 'postprofilevideo') {
    return renderProfileVideoThumbnail(post);
  }
  return (
    <article className={`post type-post ${post.format}`}>
      {renderThumbnail()}
      <PostContent post={post} />
    </article>
  );
};
export default Post;
