import React, { Component } from "react";
import Post from "../../components/posts/Post";

class VideoPlaylistsSection extends Component {
  render() {
    const VideoPost1 = {
      format: "type-post",
      title: "Spearfishing in Paradise",
      videoUploadDate: "3 days",
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: "",
      videoPlaylistItem: true
    };
    return (
      <section className="play-list">
        <div className="section-padding">
          <div className="container">
            <h2 className="section-title">Video playlists</h2>
            <div
              id="list-slider"
              className="list-slider owl-carousel owl-theme"
            >
              <div className="item">
                <Post post={VideoPost1} />
              </div>
              <div className="item">
                <Post post={VideoPost1} />
              </div>
              <div className="item">
                <Post post={VideoPost1} />
              </div>
              <div className="item">
                <Post post={VideoPost1} />
              </div>
              <div className="item">
                <Post post={VideoPost1} />
              </div>
              <div className="item">
                <Post post={VideoPost1} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VideoPlaylistsSection;
