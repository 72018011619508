import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import profileLogo from '../../images/author.jpg';
import profileHeaderImg from '../../images/bg3.jpg';

class FollowerCount extends Component {
  render() {
    const showFollower = false;
    const state = {
      followerCount: 3044,
    };
    return (
      <div className="bottom-right col-xs-6 text-right">
        {showFollower && (
          <>
            <button className="btn followed-btn disabled">Followed</button>
            <span className="subs-count">
              <span className="count">{state.followerCount}</span>
              {' '}
              Followers
            </span>
          </>
        )}
      </div>
    );
  }
}
class ProfileHeader extends Component {
  render() {
    const { user } = this.props.auth;
    const state = {
      name: user.name,
      followerCount: 3044,
      showProfileHeader: false,
    };
    return (
      <section className="author-heading">
        <div className="section-padding">
          <div className="container">
            <div className="heading-top">
              {state.showProfileHeader && (
                <div
                  className="author-cover-image background-bg"
                  data-image-src={profileHeaderImg}
                >
                  <div className="overlay" />
                </div>
              )}
            </div>
            <div className="heading-bottom">
              <div className="bottom-left col-xs-6">
                {state.showProfileHeader && (
                <div className="author-image">
                  <img src={profileLogo} alt="" />
                </div>
                ) }
                <h3 className="author-name">
                  <Link to="/profile/home">
                    {user.firstName}
                    {' '}
                    {user.lastName}
                  </Link>
                </h3>
              </div>
              <FollowerCount />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ProfileHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(ProfileHeader);
