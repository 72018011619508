import React, { Component } from 'react';

class FacebookWidget extends Component {
  render() {
    return (
      <div className="widget widget_facebbok_feed">
        <div className="widget-details">
          <div
            className="fb-page"
            data-href="https://www.facebook.com/spearchannel/"
            data-tabs=""
            data-width="320"
            data-height="480"
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
          >
            <blockquote
              cite="https://www.facebook.com/spearchannel/"
              className="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/spearchannel/">Spearchannel</a>
            </blockquote>
          </div>
        </div>
      </div>
    );
  }
}

export default FacebookWidget;
