import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";

export const updateUser = (userId, userData) => dispatch => {
  axios
    .put("/api/users/" + userId, userData)
    .then(res => {
      console.log("update response", res);
      // Set current user
      dispatch(setCurrentUser(res.data));
    }) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
