import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { submitPost } from "../../../../actions/video";
import "../SubmitForm.css";
import { useForm } from "react-hook-form";

const SubmitContentUploadForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {}
  });

  const navigate = useNavigate();

  let [videoUpload, setVideoUpload] = useState(false);

  const onVideoUploadChange = () => {
    setVideoUpload(!videoUpload);
  };

  const onSubmit = data => {
    console.log("submitting data: ", data);
    navigate("/quicksubmit/completion");
  };

  const VideoLinkInput = () => {
    return (
      <p className="form-input">
        <label htmlFor="videolink">Video Link</label>
        <input
          id="videolink"
          type="text"
          name="videolink"
          placeholder="Your Video Link"
          className={"form-control"}
          {...register("videolink", { required: false })}
        />
        <span className="red-text"></span>
      </p>
    );
  };

  const VideoUploadInput = () => {
    return (
      <p className="form-input">
        <label htmlFor="videoUpload">Video Upload</label>
        <input
          id="videoUpload"
          type="text"
          name="videoUpload"
          placeholder="Upload your video here."
          className={"form-control"}
          {...register("videoUpload", { required: false })}
        />
        <span className="red-text"></span>
      </p>
    );
  };

  /*useEffect(() => {
    window.scrollTo(0, 0);
  }, []);*/

  return (
    <form
      className="register-form"
      name="submissionForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="title"></h2>
      <div className="form-input">
        <div className="form-group">
          <div className="formLabel">Video Upload / Video Link</div>
          <label className="switch" htmlFor="videoSubmissionMethod">
            <input
              name="videoUploadToggle"
              id="videoUploadToggle"
              type="checkbox"
              checked={videoUpload}
            />
            <span onClick={onVideoUploadChange} className="slider round " />
          </label>
        </div>
      </div>
      {videoUpload && <VideoLinkInput></VideoLinkInput>}
      {!videoUpload && <VideoUploadInput></VideoUploadInput>}
      <div className="btn-array">
        <p>
          <input type="submit" value={"Next"} />
        </p>
      </div>
    </form>
  );
};

SubmitContentUploadForm.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { submitPost })(SubmitContentUploadForm);
