import axios from "axios";
import { GET_ERRORS } from "./types";

// Login - get user token
export const submitPost = postData => dispatch => {
  axios
    .post("/api/submissions", postData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      //const { token } = res.data;
      //localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      console.log("post submission response: ", res);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
