import React from "react";

const DiverThumbnail = () => {
  return (
    <div className="item media">
      <div className="item-thumb media-left">
        <img
          src="https://s3.amazonaws.com/spearchannel-content/spearo_1.jpg"
          alt="Thumb"
        />
      </div>
      <div className="item-details media-body">
        <h3 className="author-title">
          <a href="/">Random Diver</a>
        </h3>
        <button className="btn follow-btn">Follow</button>
      </div>
    </div>
  );
};

export default DiverThumbnail;
