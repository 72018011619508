import React, { Component } from "react";
import SideBar from "../SideBar";
import PaginationWidget from "../widgets/PaginationWidget";
import Post from "../posts/Post";
import BannerTwo from "../banner/BannerTwo";

class Blog extends Component {
  render() {
    const VideoPost1 = {
      format: "format-standard",
      title: "Spearfishing in Paradise",
      videoUploadDate: "3 days",
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };

    const GalleryPost = {
      format: "format-gallery",
      title: "Thailand Spearfishing",
      videoUploadDate: "2 days",
      commentCount: 280,
      viewCount: 4674,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };

    const AudioPost1 = {
      format: "format-audio",
      title: "Spearfishing In Australia Audio Podcast",
      videoUploadDate: "5 days",
      commentCount: 666,
      viewCount: 2333,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };

    const LinkPost1 = {
      format: "format-link",
      title: "Spearfishing Guide in Panama Link",
      videoUploadDate: "23 days",
      commentCount: 3,
      viewCount: 89,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };

    const TextPost = {
      format: "format-textonly",
      title: "Spearfishing Text Post",
      videoUploadDate: "13 days",
      commentCount: 45,
      viewCount: 12,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };

    const VideoPost2 = {
      format: "format-video",
      title: "Florida Spearfishing Tournament Video Compilation",
      videoUploadDate: "26 days",
      commentCount: 980,
      viewCount: 1900,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };
    return (
      <>
        <BannerTwo></BannerTwo>
        <section className="blog-posts">
          <div className="section-padding">
            <div className="container">
              <h1 className="section-title">Blog</h1>
              <div className="row">
                <div className="col-sm-8">
                  <Post post={VideoPost1} />
                  <Post post={GalleryPost} />
                  <Post post={VideoPost2} />
                  <Post post={AudioPost1} />
                  <Post post={LinkPost1} />
                  <Post post={TextPost} />
                  <Post post={VideoPost1} />
                  <PaginationWidget />
                </div>
                <div className="col-sm-4">
                  <SideBar />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Blog;
