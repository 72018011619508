import React from 'react';
import { Outlet } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import ProfileNavigation from './ProfileNavigation';

const Profile = () => (
  <div>
    <ProfileHeader />
    <section className="author-page-contents">
      <div className="section-padding">
        <div className="container">
          <ProfileNavigation />
          <Outlet />
        </div>
      </div>
    </section>
  </div>
);

export default Profile;
