import React from "react";
import ProfileAside from "./ProfileAside";
import Post from "../../components/posts/Post";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const ProfileHome = () => {
  const VideoPost1 = {
    format: "type-post",
    title: "Spearfishing in Paradise",
    mediaPost: true,
    videoUploadDate: "3 days",
    commentCount: 569,
    viewCount: 1744,
    postDescriptionShort:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    thumbnailImageHref: ""
  };

  const VideoPost2 = {
    format: "type-post",
    title: "Spearfishing in Paradise",
    videoUploadDate: "3 days",
    commentCount: 569,
    viewCount: 1744,
    postDescriptionShort:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    thumbnailImageHref: ""
  };

  return (
    <div className="author-contents">
      <div className="row">
        <div className="col-sm-8">
          <h2 className="section-title">Watch Now</h2>
          <div className="recent-videos">
            <div className="row">
              <div className="col-md-6">
                <div className="left-panel">
                  <Post post={VideoPost2} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="right-panel">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>
              </div>
            </div>
          </div>

          <h2 className="section-title">Recent Uploads</h2>

          <OwlCarousel className="upload-videos-slider owl-carousel owl-theme">
            <div className="item">
              <Post post={VideoPost2} />
            </div>

            <div className="item">
              <Post post={VideoPost2} />
            </div>

            <div className="item">
              <Post post={VideoPost2} />
            </div>

            <div className="item">
              <Post post={VideoPost2} />
            </div>
          </OwlCarousel>

          <h2 className="section-title">Home Section 2</h2>

          <OwlCarousel className="category-slider-01 owl-carousel owl-theme">
            <div className="item">
              <Post post={VideoPost2} />
            </div>

            <div className="item">
              <Post post={VideoPost2} />
            </div>

            <div className="item">
              <Post post={VideoPost2} />
            </div>

            <div className="item">
              <Post post={VideoPost2} />
            </div>
          </OwlCarousel>

          <h2 className="section-title">Diving Blog</h2>

          <div className="play-list play-list-3">
            <div
              id="list-slider"
              className="list-slider owl-carousel owl-theme"
            >
              <div className="item">
                <Post post={VideoPost1} />
                <Post post={VideoPost1} />
                <Post post={VideoPost1} />
              </div>

              <div className="item">
                <Post post={VideoPost1} />
                <Post post={VideoPost1} />
                <Post post={VideoPost1} />
              </div>
              <div className="item">
                <Post post={VideoPost1} />
                <Post post={VideoPost1} />
                <Post post={VideoPost1} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <ProfileAside />
        </div>
      </div>
    </div>
  );
};

export default ProfileHome;
