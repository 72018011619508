import React, { Component } from "react";

class PaginationWidget extends Component {
  render() {
    return (
      <div className="pagination">
        <a href="/" className="prev">
          <i className="ti-arrow-left"></i>
        </a>
        <a href="/" className="active">
          1
        </a>
        <a href="/">2</a>
        <a href="/">3</a>
        <a href="/">4</a>
        <a href="/" className="next">
          <i className="ti-arrow-right"></i>
        </a>
      </div>
    );
  }
}

export default PaginationWidget;
