import React, { Component } from "react";
import Post from "../../components/posts/Post";

class PlaylistList extends Component {
  render() {
    //TODO: add media to format
    const VideoPost1 = {
      format: "type-post",
      title: "Spearfishing in Paradise",
      videoUploadDate: "3 days",
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: "",
      mediaPost: true,
      postPlaylist: true
    };

    return (
      <div className="play-list play-list-4">
        <Post post={VideoPost1} />
        <Post post={VideoPost1} />
        <Post post={VideoPost1} />
        <Post post={VideoPost1} />
        <Post post={VideoPost1} />
        <Post post={VideoPost1} />
      </div>
    );
  }
}

export default PlaylistList;
