import React, { useState } from "react";
import profileHeaderImg from "../../images/bg3.jpg";
import TopMagazines from "./TopMagazines";
const MagazineThumbnail = props => {
  const { thumbnail, title } = props;

  const thumbnailStyle = {
    height: "320px"
  };
  let [showStar] = useState(false);
  return (
    <div className="col-sm-4 col-xs-6">
      <article className="post type-post">
        <div className="entry-thumbnail">
          <a href="/magazine/1">
            <img
              style={thumbnailStyle}
              src={thumbnail}
              alt="Movie Thumbnail"
            ></img>
          </a>
          {showStar && (
            <span className="rating">
              <i className="fa fa-star"></i>
              <span className="count">7.8</span>
            </span>
          )}
        </div>
        <div className="entry-content">
          <h3 className="entry-title">
            <a href="/magazine/1">{title}</a>
          </h3>
        </div>
      </article>
    </div>
  );
};

const MagazineThumbnailList = () => {
  let sampleData = [
    {
      title: "Hawaii Skin Diver Vol. 1 - Tuna lurking in the deep.",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 2- Tuna Master",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 4",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_crab.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1 - Tuna Master",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 2 - Spearing Tuna in the Pacific",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_bw_4.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 5",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_dock.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 2 - Spearing Tuna in the Pacific",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_bw_4.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1 - Tuna Master",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 4",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_crab.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 2 - Spearing Tuna in the Pacific",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_bw_4.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 5",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_dock.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1 - Tuna Master",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 4",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_crab.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 2 - Spearing Tuna in the Pacific",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_bw_4.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 5",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_dock.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1 - Tuna Master",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 4",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_crab.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 2 - Spearing Tuna in the Pacific",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_bw_4.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 5",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_dock.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1 - Tuna Master",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 4",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_crab.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 2 - Spearing Tuna in the Pacific",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_bw_4.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 5",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_dock.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 3 - Ahi on the Table",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_ahi_table.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 4",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_crab.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 5",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_dock.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/ryder_donkey.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1",
      thumbnail: "https://spearchannel-content.s3.amazonaws.com/ryder_trees.jpg"
    }
  ];

  const sectionStyle = {
    "padding-top": "50px"
  };
  const sectionStylePaddingBottom = {
    "padding-bottom": "35px"
  };

  return (
    <>
      <section style={sectionStylePaddingBottom} className="author-heading">
        <div className="section-padding">
          <div className="container">
            <div className="heading-top">
              <div
                className="author-cover-image background-bg"
                data-image-src={profileHeaderImg}
              >
                <div className="overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TopMagazines></TopMagazines>

      <section className="video-contents">
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                <div className="movie-contents-area">
                  <h2 className="section-title">
                    Hawaii Skin Diver x Spearchannel
                  </h2>
                  <div className="entry-content">
                    <p>
                      <strong>
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat non proident. Sed ut
                        perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium
                      </strong>
                      <br></br>
                      Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                      aut odit aut fugit, sed quia consequuntur magni dolores
                      eos qui ratione voluptatem sequi nesciunt. Neque porro
                      quisquam est, qui dolorem ipsum quia dolor sit amet,
                      consectetur, adipisci velit, sed quia non numquam eius
                      modi tempora incidunt ut labore et dolore magnam aliquam
                      quaerat voluptatem.
                    </p>
                    <blockquote>
                      “Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.”
                      <span className="name">- Source Name</span>
                    </blockquote>
                    <p>
                      Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                      aut odit aut fugit, sed quia consequuntur magni dolores
                      eos qui ratione voluptatem sequi nesciunt. Neque porro
                      quisquam est, qui dolorem ipsum quia dolor sit amet,
                      consectetur, adipisci velit, sed quia non numquam eius
                      modi tempora incidunt ut labore et dolore magnam aliquam
                      quaerat voluptatem.
                    </p>
                  </div>
                </div>
                <div style={sectionStyle} className="movie-contents-area">
                  <h2 className="section-title">All HSD Issues</h2>
                  <div className="movie-contents">
                    {sampleData.map((magazine, i) => {
                      return (
                        <MagazineThumbnail
                          key={i}
                          thumbnail={magazine.thumbnail}
                          title={magazine.title}
                        ></MagazineThumbnail>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <aside className="sidebar">
                  <div className="widget widget_search">
                    <div className="widget-details">
                      <form method="get" className="search-form" action="#">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Hawaii Skin Diver"
                          name="search"
                          title="Search.."
                          required=""
                        ></input>
                        <input
                          type="submit"
                          className="form-control"
                          name="submit"
                        ></input>
                      </form>
                    </div>
                  </div>

                  <div className="widget widget_categories">
                    <h3 className="widget-title">Magazine Categories</h3>
                    <div className="widget-details">
                      <a href="/">
                        Spearfishing <span className="count">58</span>
                      </a>
                      <a href="/">
                        Bluewater <span className="count">58</span>
                      </a>
                      <a href="/">
                        Reef <span className="count">58</span>
                      </a>
                      <a href="/">
                        Cooking <span className="count">58</span>
                      </a>
                      <a href="/">
                        Traveling <span className="count">58</span>
                      </a>
                      <a href="/">
                        Freediving <span className="count">58</span>
                      </a>
                      <a href="/">
                        Hawaii <span className="count">58</span>
                      </a>
                    </div>
                  </div>

                  <div className="widget widget_quality">
                    <h3 className="widget-title">Magazine Era</h3>
                    <div className="widget-details">
                      <button>1980`&apos;`s</button>
                      <button>1990`&apos;`s</button>
                      <button>2000`&apos;`s</button>
                      <button>2010`&apos;`s</button>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MagazineThumbnailList;
