import React, { Component } from 'react';
import './css/style.css';
class ComingSoon extends Component {
  render() {
	return (
        <div>
    <div class="relative fullscreen text-center home-area home-bg-image ripple">
        <div class="table-box">
            <div class="table-cell">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="space-50"></div>
                            <div class="countdown">
                                <ul class="color_white list-inline list-unstyled" id="countdown"></ul>
                            </div>
                            <div class="space-20"></div>
                            <h1 class="logo">Spearchannel</h1>
                            <h2 class="headline">Coming Soon</h2>
                            <div class="space-20"></div>
                            <ul class="list-unstyle list-inline social">
                                <li><a href="http://www.facebook.com/spearchannel"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="http://www.instagram.com/spearchannel"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                            <div class="space-30"></div>
                            <div class="list-button">
                                <a href="#coming-soon" class="btn btn-info" data-toggle="modal">Coming Soon</a>
                                <a href="#project" class="btn btn-info" data-toggle="modal">Coming Soon</a>
                                <a href="#contact" class="btn btn-info" data-toggle="modal">Contact Us</a>
                            </div>
                            <div class="space-30"></div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4">
                                    <form action="#" class="subscribe relative" id="mc-form">
                                        <label class="mt10" for="mc-email"></label>
                                        <div class="input-group">
                                            <input type="email" class="form-control" id="mc-email" placeholder="Email Address"></input>
                                            <div class="input-group-btn">
                                                <button type="submit" class="btn btn-default">Subscribe</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="space-20"></div>
                            <p >Photos courtesy of John Harrington <a  href="http://instagram.com/johnny5dubya">@johnny5dubya</a>  </p><p style={{fontSize: 10 + 'px'}}>&copy;Copyrights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="coming-soon" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <div >
                            <h2 class="text-uppercase">Coming Soon</h2>
                                                        <p>For the past year, Spearchannel has focused on posting the latest and greatest spearfishing videos, photos, gear, tips and tricks to inspire and engage spearos!</p><p>

                            We're excited to announce that our new website (coming soon) will provide even more valuable content for people of all ages, backgrounds and skill levels! </p><p>

                            Stay tuned and subscribe to our mailing list for updates. </p>
                            
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
		);
	}
}

export default ComingSoon;
