/*
 *  Viral Videos Reducer
 */

import { RECEIVE_VIRAL_VIDEOS } from "../../actions/types";

export default function(viralVideos = {}, action) {
  switch (action.type) {
    case RECEIVE_VIRAL_VIDEOS:
      return action.payload;

    default:
      return viralVideos;
  }
}
