import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InstagramWidget from '../widgets/InstagramWidget';
import FacebookWidget from '../widgets/FacebookWidget';
import CategoriesWidget from '../widgets/CategoriesWidget';
import SearchWidget from '../widgets/SearchWidget';
import LatestPostWidget from '../widgets/LatestPostWidget';
import SocialWidget from '../widgets/SocialWidget';
import VideoBanner from '../posts/VideoBanner';
import VideoPostBody from './VideoPostBody';
import RelatedVideos from './RelatedVideos';
import { getPostById } from '../../actions/postActions';
import { showSuccessAction, showFailureAction } from '../../actions/toastActions';

function VideoPostPage(props) {
  const beta = false;
  const { postId } = useParams();
  useEffect(() => {
    props
      .getPostById(postId)
      .then((res) => {})
      .catch((error) => {});
  }, [props.getPostById]);

  const post = useSelector((state) => state.currentPost);

  if (Object.keys(post).length === 0 && post.constructor === Object) {
    return (<div />);
  }

  return (
    <div>
      <VideoBanner post={post} />
      <section className="blog-posts video-post">
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <VideoPostBody post={post} />
                {beta && <RelatedVideos />}
              </div>

              {beta && (
                <div className="col-sm-4">
                  <aside className="sidebar">
                    <SearchWidget />
                    <CategoriesWidget />
                    <SocialWidget />
                    <LatestPostWidget />
                    <InstagramWidget />
                    <FacebookWidget />
                  </aside>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getPostById,
  showSuccessAction,
  showFailureAction,
})(VideoPostPage);
