import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submitPost } from "../../../../actions/video";
import "../SubmitForm.css";
import { useForm } from "react-hook-form";

const SubmitContentCompletionForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {}
  });

  const onSubmit = data => {
    console.log("submitting data: ", data);
    
  };

  /*useEffect(() => {
    window.scrollTo(0, 0);
  }, []);*/

  return (
    <form
      className="register-form"
      name="submissionForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="title">Almost done!</h2>
      <p className="form-input">
        <label htmlFor="socialType">Tags</label>
        <select
          id="socialType"
          type="select"
          name="socialType"
          placeholder="Instagram"
          className={"form-control"}
          {...register("socialType", { required: true })}
        >
          <option>Freediving</option>
          <option>Bluewater</option>
          <option>Shorediving</option>
          <option>Reef</option>
        </select>
      </p>
      <p className="form-input">
        <label htmlFor="socialMediaHandle">Description</label>
        <input
          id="socialHandle"
          type="text"
          name="socialHandle"
          placeholder=""
          className={"form-control"}
          {...register("socialhandle", { required: true })}
        />
      </p>

      <div className="btn-array">
        <p>
          <input type="submit" value={"Submit Content"} />
        </p>
      </div>
    </form>
  );
};

SubmitContentCompletionForm.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps, { submitPost })(SubmitContentCompletionForm);
