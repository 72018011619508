import React, { Component } from 'react'
import SideBarAd from '../../images/sidebar/ad.jpg';
class BannerAdWidget extends Component {
	render(){
		return (
			<div className="widget widget_banner_ad">
                <div className="widget-details">
                  <a href="https://www.facebook.com"><img src={SideBarAd} alt="Banner ad"/></a> 
                </div>
              </div>
  		);
	}
}

export default BannerAdWidget;