import React from "react";
import MagazineTabContentSection from "../Magazine/MagazineTabContentSection";
import MagazineSideRail from "../Magazine/MagazineSideRail";

const Magazine = () => {
  const magazineThumbnail =
    "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg";
  const showMagazineTabs = true;
  const showMagazineSideRail = true;
  return (
    <section className="video-contents">
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="movie-contents-area">
                <div className="movie-single">
                  <article className="post type-post hawaii-skin-diver">
                    <div className="entry-content media">
                      <div class="movie-thumb media-left">
                        <img src={magazineThumbnail} alt="Movie Poster"></img>
                      </div>

                      <div className="movie-details media-body">
                        <div className="entry-meta">
                          <span className="author">
                            <a href="/">HSD</a>
                          </span>
                          <span>
                            <time dateTime="2017-05-23">
                              15 September, 2017
                            </time>{" "}
                            <span className="country">Hawaii</span>
                          </span>
                          <span className="category tag">
                            <a href="/">Freediving</a>,{" "}
                            <a href="/">Spearfishing</a>
                          </span>
                        </div>

                        <h2 className="entry-title">
                          Hawaii Skin Diver Issue 69
                        </h2>

                        <p>
                          What would an ocean be without a monster lurking in
                          the darkness? It would be like sleep without dreams.
                        </p>
                        <p>
                          "At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum deleniti
                          atque corrupti quos dolores et quas molestias
                          excepturi sint occaecati cupiditate non provident,
                          similique sunt in culpa qui officia deserunt mollitia
                          animi, id est laborum et dolorum fuga. Et harum quidem
                          rerum facilis est et expedita distinctio. Nam libero
                          tempore, cum soluta nobis est eligendi optio cumque
                          nihil impedit quo minus id quod maxime placeat facere
                          possimus, omnis voluptas assumenda est, omnis dolor
                          repellendus. Temporibus autem quibusdam et aut
                          officiis debitis aut rerum necessitatibus saepe
                          eveniet ut et voluptates repudiandae sint et molestiae
                          non recusandae. Itaque earum rerum hic tenetur a
                          sapiente delectus, ut aut reiciendis voluptatibus
                          maiores alias consequatur aut perferendis doloribus
                          asperiores repellat."
                        </p>
                        <hr></hr>
                        <h5>View this magazine:</h5>

                        <div className="entry-thumbnail">
                          <iframe
                            frameborder="0"
                            title="Magazine Frame"
                            width="400"
                            height="300"
                            src="https://75d10732.flowpaper.com/HSD69spearchanneltest/#PreviewMode=Miniature"
                            scrolling="no"
                            marginwidth="0"
                            marginheight="0"
                            allowFullScreen
                          ></iframe>
                        </div>
                        <button className="btn follow-btn">
                          Purchase a physical copy
                        </button>
                        <hr></hr>

                        <div className="movie-meta">
                          <ul>
                            <li>
                              <span>Editor:</span>{" "}
                              <a href="/">Clifford Cheng</a>
                            </li>
                            <li>
                              <span>Pictures:</span>{" "}
                              <a href="/">Daniel Hulme</a>,{" "}
                              <a href="/">Perrin James</a> |{" "}
                              <a href="/">3 more credits »</a>
                            </li>
                            <li>
                              <span>Features:</span> <a href="/">Riffe</a>,{" "}
                              <a href="/">Hawaii</a> | <a href="/">Ulua</a>
                            </li>

                            <li>
                              <span>Epic Words:</span>Joe Farlo
                            </li>
                            <li>
                              <span>Epic Photo:</span>Joe Farlo + Richard Balta
                            </li>
                            <li>
                              <span>Six Months in Tonga Photo:</span>Michael
                              Takach + Jessie Cripps
                            </li>
                            <li>
                              <span>Six Months in Tonga Words:</span>Michael
                              Takach
                            </li>
                            <li>
                              <span>Carved Hammered Welded: Sculptures:</span>
                              Casey Parlette
                            </li>
                            <li>
                              <span>"The Ascension" words by: </span>Ryder DeVoe
                            </li>
                            <li>
                              <span>Micronesia: Words and Photo By:</span> Woody
                              Brown
                            </li>

                            <li>
                              <span>Rance At 1°: Words and Photo By: </span>Alex
                              Voyer
                            </li>
                          </ul>
                        </div>

                        <div className="tags">
                          <a href="/">Hawaii </a>
                          <a href="/">Freediving</a>
                          <a href="/">Spearfishing</a>
                        </div>
                      </div>
                    </div>
                  </article>

                  {showMagazineTabs && (
                    <MagazineTabContentSection></MagazineTabContentSection>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              {showMagazineSideRail && <MagazineSideRail></MagazineSideRail>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Magazine;
