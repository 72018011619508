import React from "react";
const BannerTwo = () => {
  const BannerPost = () => {
    return (
      <article className="post type-post">
        <div className="entry-thumbnail">
          <img
            src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=960&mh=540"
            alt="Thumbnail"
          />
        </div>
        <div className="entry-content">
          <h2 className="entry-title">
            <a href="single-post.html">Spearfishing Guide in Panama Link</a>
          </h2>
          <div className="entry-meta">
            <span className="author">
              <a href="/">Diver A</a>
            </span>
            <span>
              <i className="fa fa-clock-o"></i>{" "}
              <time dateTime="PT5M">5 months</time>
            </span>
            <span>
              <a href="/">
                <i className="fa fa-comment-o"></i>{" "}
                <span className="count">351</span>
              </a>
            </span>
            <span>
              <i className="fa fa-eye"></i>{" "}
              <span className="count">17,989</span>
            </span>
          </div>
        </div>
      </article>
    );
  };
  return (
    <section className="banner-section banner-posts banner-posts-02">
      <div className="col-sm-3">
        <BannerPost></BannerPost>
        <BannerPost></BannerPost>
      </div>

      <div className="col-sm-6">
        <BannerPost></BannerPost>
      </div>

      <div className="col-sm-3">
        <BannerPost></BannerPost>

        <BannerPost></BannerPost>
      </div>
    </section>
  );
};

export default BannerTwo;
