import React, { Component } from "react";
import Post from "../posts/Post";

class DailyMostViewed extends Component {
  render() {
    const VideoPost1 = {
      format: "type-post",
      title: "Spearfishing in Paradise",
      videoUploadDate: "3 days",
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };

    return (
      <div className="widget widget_most_viewd">
        <h3 className="widget-title">Today's most viewed</h3>
        <div className="widget-details">
          <Post post={VideoPost1}></Post>
        </div>
      </div>
    );
  }
}

export default DailyMostViewed;
