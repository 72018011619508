import React from 'react';
import '../App.css';
import { Form, Field } from 'react-final-form';
import footerLogo from '../images/spearchannel_logo_bw_footer.png';
import footerBg from '../images/bg-footer2.jpg';

const FooterSubscribe = () => {};
function PopularPosts() {
  return (
    <div className="col-md-4 col-sm-4">
      <div className="widget widget_popular_posts">
        <h3 className="widget-title">Popular Posts</h3>

        <div className="widget-details">
          <article className="post type-post media">
            <div className="entry-thumbnail media-left">
              <img
                src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
                alt="Entry Thumbnail"
              />
            </div>

            <div className="entry-content media-body">
              <h3 className="entry-title">
                <a href="blog-single.html">Spearfishing in Paradise</a>
              </h3>
              <div className="entry-meta">
                <span>
                  <a href="/">
                    <i className="fa fa-comment-o" />
                    {' '}
                    <span className="count">46</span>
                    {' '}
                    Comments
                  </a>
                </span>
              </div>
            </div>
          </article>

          <article className="post type-post media">
            <div className="entry-thumbnail media-left">
              <img
                src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
                alt="Entry Thumbnail"
              />
            </div>

            <div className="entry-content media-body">
              <h3 className="entry-title">
                <a href="blog-single.html">Spearfishing in Paradise</a>
              </h3>
              <div className="entry-meta">
                <span>
                  <a href="/">
                    <i className="fa fa-comment-o" />
                    {' '}
                    <span className="count">46</span>
                    {' '}
                    Comments
                  </a>
                </span>
              </div>
            </div>
          </article>

          <article className="post type-post media">
            <div className="entry-thumbnail media-left">
              <img
                src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
                alt="Entry Thumbnail"
              />
            </div>

            <div className="entry-content media-body">
              <h3 className="entry-title">
                <a href="blog-single.html">Spearfishing in Paradise</a>
              </h3>
              <div className="entry-meta">
                <span>
                  <i className="fa fa-comment-o" />
                  {' '}
                  <span className="count">46</span>
                  {' '}
                  Comments
                </span>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

function TagsColumn() {
  return (
    <div className="col-md-4 col-sm-4">
      <div className="widget widget_tags">
        <h3 className="widget-title">Tag Clouds</h3>

        <div className="widget-details">
          <a href="/" className="tag-link">
            Freediving
          </a>
          <a href="/" className="tag-link">
            Spearfishing
          </a>
          <a href="/" className="tag-link">
            Florida
          </a>
          <a href="/" className="tag-link">
            Cooking
          </a>
          <a href="/" className="tag-link">
            Shorediving
          </a>
          <a href="/" className="tag-link">
            Bluewater
          </a>
          <a href="/" className="tag-link">
            Reef
          </a>
          <a href="/" className="tag-link">
            Wreck
          </a>
          <a href="/" className="tag-link">
            Grouper
          </a>
          <a href="/" className="tag-link">
            Snapper
          </a>
          <a href="/" className="tag-link">
            Australia
          </a>
          <a href="/" className="tag-link">
            Hawaii
          </a>
          <a href="/" className="tag-link">
            Florida
          </a>
          <a href="/" className="tag-link">
            Bahamas
          </a>
        </div>
      </div>
    </div>
  );
}
function Footer() {
  const showTagClouds = false;
  const showPopularPosts = false;

  const onSubmit = (form) => {
    console.log('submitting in footer');
    console.log(form);
  };
  return (
    <footer className="site-footer">
      <div className="footer-top background-bg" src={footerBg}>
        <div className="overlay">
          <div className="section-padding">
            <div className="container">
              <div className="row">
                <div className="footer-widgets">
                  <div className="col-md-4 col-sm-4">
                    <div className="widget widget_about">
                      <div className="widget-details">
                        <img className="footer-logo" src={footerLogo} alt="Logo" />
                        <p>
                          The largest Spearfishing media outlet in the world! Submit your videos and pictures to be
                          featured on our platform.
                        </p>

                        <div className="footer-social">
                          <a href="https://www.facebook.com/Spearchannel/">
                            <i className="fa fa-facebook" />
                          </a>
                          <a href="https://www.instagram.com/spearchannel">
                            <i className="fa fa-instagram" />
                          </a>
                          <a href="https://www.vimeo.com/Spearchannel/">
                            <i className="fa fa-vimeo-square" />
                          </a>
                          <a href="https://www.youtube.com/channel/UCwIU3WVRzHxZ5HAycJbxWwA">
                            <i className="fa fa-youtube" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showPopularPosts && <PopularPosts />}
                  {showTagClouds && <TagsColumn />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="padding">
          <div className="container">
            <div className="copyright text-center">
              ©
              {' '}
              <a href="https://www.facebook.com/Spearchannel/">SPEARCHANNEL</a>
              {' '}
              2024 | Designed by
              {' '}
              <a href="/">Spearchannel</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
