/*
 *  Homepage Posts Reducer
 */

import { RECEIVE_HOMEPAGE_POSTS } from "../../actions/types";

export default function(homepagePosts = {}, action) {
  switch (action.type) {
    case RECEIVE_HOMEPAGE_POSTS:
      return action.payload;
    default:
      return homepagePosts;
  }
}
