import React, { Component } from "react";

class CategoriesWidget extends Component {
  render() {
    return (
      <div className="widget widget_categories">
        <h3 className="widget-title">Blog Categories</h3>
        <div className="widget-details">
          <a href="/">Spearfishing</a>
          <a href="/">Freediving</a>
          <a href="/">Cooking</a>
          <a href="/">Travel</a>
          <a href="/">Florida</a>
          <a href="/">Bluewater</a>
        </div>
      </div>
    );
  }
}

export default CategoriesWidget;
