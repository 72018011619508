import React from "react";

const CheckoutOutThumbnail = props => {
  let { thumbnail } = props;
  return (
    <article className="post type-post media">
      <div className="entry-thumbnail media-left">
        <img src={thumbnail} alt="Entry Thumbnail"></img>
      </div>

      <div className="entry-content media-body">
        <h3 className="entry-title">
          <a href="/movie/1">Hawaii Skin Diver Issue 1</a>
        </h3>
        <div className="entry-meta">
          <span>
            <i className="fa fa-clock-o"></i>{" "}
            <time dateTime="2017-05-05">05 May, 2017</time>
          </span>
        </div>
      </div>
    </article>
  );
};
const CheckOutAlsoWidget = props => {
  let samplePosts = [
    {
      title: "Hawaii Skin Diver Vol 1",
      link: "/movie/1",
      date: "05 May, 2017",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol 1",
      link: "/movie/1",
      date: "05 May, 2017",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol 1",
      link: "/movie/1",
      date: "05 May, 2017",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    }
  ];
  return (
    <div className="widget widget_latest_post">
      <h3 className="widget-title">Check out other HSD issues</h3>
      <div className="widget-details">
        {samplePosts.map((contentItems, i) => {
          return (
            <CheckoutOutThumbnail
              key={i}
              title={contentItems.title}
              link={contentItems.link}
              date={contentItems.date}
              thumbnail={contentItems.thumbnail}
            ></CheckoutOutThumbnail>
          );
        })}
      </div>
    </div>
  );
};

export default CheckOutAlsoWidget;
