import { combineReducers } from "redux";
import homepagePostsReducer from "./homepagePostsReducer";
import latestVideosReducer from "./latestVideosReducer";
import postsReducer from "./postsReducer";
import topVideoPlaylistsReducer from "./topVideoPlaylistsReducer";
import videosReducer from "./videosReducer";
import viralVideosReducer from "./viralVideosReducer";

export default combineReducers({
  posts: postsReducer,
  homepagePosts: homepagePostsReducer,
  videos: videosReducer,
  topVideoPlaylists: topVideoPlaylistsReducer,
  latestVideos: latestVideosReducer,
  viralVideos: viralVideosReducer
});
