import React, { Component } from "react";
class Contact extends Component {
  render() {
    return (
      <div>
        <section id="google-map">
          <div class="map-container">
            <div id="googleMaps" class="google-map-container"></div>
          </div>
        </section>

        <section class="contact-details">
          <div class="section-padding">
            <div class="container">
              <div class="row">
                <div class="col-sm-8">
                  <h2 class="section-title">Contact us</h2>

                  <form
                    action="email.php"
                    method="post"
                    class="wpcf7-form contact-form"
                  >
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value=""
                      class="wpcf7-form-control"
                      placeholder="Name (required)"
                      required=""
                    ></input>

                    <input
                      type="email"
                      id="email"
                      name="email"
                      value=""
                      class="wpcf7-form-control"
                      placeholder="Email (required)"
                      required=""
                    ></input>

                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      value=""
                      class="wpcf7-form-control"
                      placeholder="Subject"
                    ></input>

                    <textarea
                      id="message"
                      name="message"
                      class="wpcf7-form-control"
                      placeholder="Message (required)"
                      required=""
                    ></textarea>

                    <input
                      type="submit"
                      id="submit"
                      name="submit"
                      class="wpcf7-form-control btn"
                      value="Send Message"
                    ></input>
                    <div class="contact-message"></div>
                    <div class="error-message"></div>
                  </form>
                </div>

                <div class="col-sm-4">
                  <aside class="sidebar">
                    <div class="widget widget_address">
                      <div class="item media">
                        <div class="item-icon media-left">
                          <i class="ti-location-pin"></i>
                        </div>
                        <div class="item-details media-body">
                          <h3 class="item-title">Address</h3>
                          <span>Fort Lauderdale, Florida</span>
                        </div>
                      </div>

                      <div class="item media">
                        <div class="item-icon media-left">
                          <i class="ti-mobile"></i>
                        </div>
                        <div class="item-details media-body">
                          <h3 class="item-title">Phone</h3>
                          <span>+1-555-5555 (10 AM - 07 PM)</span>
                        </div>
                      </div>

                      <div class="item media">
                        <div class="item-icon media-left">
                          <i class="ti-email"></i>
                        </div>
                        <div class="item-details media-body">
                          <h3 class="item-title">Email</h3>
                          <span>
                            <a href="/">info@spearchannel.com</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
