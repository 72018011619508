import React from "react";

const HeaderMagainze = props => {
  let { title, thumbnail } = props;
  return (
    <div className="item">
      <article className="post type-post">
        <div className="entry-thumbnail">
          <a href="movie-single.html">
            <img src={thumbnail} alt="Movie Thumbnail"></img>
          </a>
        </div>
        <div className="entry-content">
          <h3 className="entry-title">
            <a href="movie-single.html">{title}</a>
          </h3>
        </div>
      </article>
    </div>
  );
};
const TopMagazines = () => {
  const magazines = [
    {
      title: "Hawaii Skin Diver Vol. 1.",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    },
    {
      title: "Hawaii Skin Diver Vol. 1",
      thumbnail:
        "https://spearchannel-content.s3.amazonaws.com/hsd/HSD_COVER.jpg"
    }
  ];
  return (
    <section className="top-videos">
      <div className="section-padding">
        <div className="container">
          <div className="recent-movie-slider owl-carousel owl-theme">
            {magazines.map((magazine, i) => {
              return (
                <HeaderMagainze
                  key={i}
                  title={magazine.title}
                  thumbnail={magazine.thumbnail}
                ></HeaderMagainze>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopMagazines;
