import React, { Component } from "react";
import "../../App.css";
import Post from "../posts/Post";

class HomePageCollage extends Component {
  render() {
    const VideoPost1 = {
      format: "type-post",
      title: "Spearfishing in Paradise",
      videoUploadDate: "3 days",
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      thumbnailImageHref: ""
    };

    return (
      <section className="banner-section banner-posts">
        <div className="col-md-6">
          <Post post={VideoPost1} />
        </div>
        <div className="col-md-6">
          <div className="col-sm-6">
            <Post post={VideoPost1} />
          </div>
          <div className="col-sm-6">
            <Post post={VideoPost1} />
          </div>
          <div className="col-sm-6">
            <Post post={VideoPost1} />
          </div>
          <div className="col-sm-6">
            <Post post={VideoPost1} />
          </div>
        </div>
      </section>
    );
  }
}

export default HomePageCollage;
