import React, { Component } from "react";
import logo from "../../images/spearchannel_logo_bw.png";

class HeaderMiddle extends Component {
  render() {
    return (
      <div className="header-middle">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="navbar-brand hidden-xs">
                <a href="/">
                  <img src={logo} alt="Site Logo" className="site-logo"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderMiddle;
