import React from "react";
import MagazineThumbnailList from "./Magazine/MagazineThumbnailList";

const HawaiiSkinDiver = () => {
  return (
    <div className="hawaiiSkinDiver">
      <MagazineThumbnailList></MagazineThumbnailList>
    </div>
  );
};

export default HawaiiSkinDiver;
