import React from "react";
import ProfileAside from "./ProfileAside";
import classnames from "classnames";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import {
  showSuccessAction,
  showFailureAction
} from "../../actions/toastActions";
import { uploadPost } from "../../actions/postActions";

const PostUpload = props => {
  const onSubmit = form => {
    const user = props.auth.user;
    const userId = user.id;
    const userName = user.firstName + " " + user.lastName;
    const youtube_id = form.url.split('=')[1];
    const postData = {
      title: form.title,
      author: {
        user_id: userId,
        name: userName,
        profile_picture: "https://www.google.com"
      },
      author_id: userId,
      tags: form.tags,
      youtube_id: youtube_id,
      privacy: form.privacy,
      url: form.url,
      description: form.description
    };
    props
      .uploadPost(postData)
      .then(res => {
        console.log("showing success action");
        props.showSuccessAction("success");
      })
      .catch(error => {
        console.log("catching error:");
        props.showFailureAction("failure");
      });
  };

  return (
    <div className="author-contents">
      <div className="row">
        <div className="col-sm-8">
          <div className="about-author">
            <div className="upload-video">
              <Form
                onSubmit={onSubmit}
                validate={values => {
                  const errors = {};
                  if (!values.title) {
                    errors.title = "Required";
                  }
                  if (!values.url) {
                    errors.url = "Required";
                  }
                  return errors;
                }}
                render={({ handleSubmit }) => (
                  <form
                    className="upload-form"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Field name="title">
                      {({ input, meta }) => (
                        <div className="form-element">
                          <label htmlFor="title">Post Title</label>
                          <input
                            {...input}
                            id="title"
                            type="text"
                            className={classnames("title form-control")}
                            placeholder="Post Title"
                          />
                          {meta.error && meta.touched && (
                            <span className="red-text">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="tags">
                      {({ input, meta }) => (
                        <div className="form-element">
                          <label htmlFor="tags">Tags</label>
                          <input
                            {...input}
                            type="text"
                            id="tags"
                            name="tags"
                            className={classnames("tag form-control")}
                            placeholder="Enter up to 5 tags and separate them with a comma"
                          />
                          {meta.error && meta.touched && (
                            <span className="red-text">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <div className="form-element">
                      <label htmlFor="privacy">Post Type</label>
                      <Field
                        id="privacy"
                        name="privacy"
                        component="select"
                        className={classnames("privacy form-control")}
                      >
                        <option value="public">Video</option>
                        <option value="private">Picture</option>
                        <option value="unlisted">Post</option>
                      </Field>
                    </div>
                    <div className="form-element">
                      <label htmlFor="privacy">Set Audience</label>
                      <Field
                        id="privacy"
                        name="privacy"
                        component="select"
                        className={classnames("privacy form-control")}
                      >
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        <option value="unlisted">Unlisted</option>
                      </Field>
                    </div>
                    <Field name="url">
                      {({ input, meta }) => (
                        <div className="form-element">
                          <label htmlFor="url">Video Url</label>
                          <input
                            {...input}
                            id="url"
                            type="text"
                            className={classnames("url form-control")}
                            placeholder="Video Url"
                          />
                          {meta.error && meta.touched && (
                            <span className="red-text">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <div className="form-element file-type">
                      <label htmlFor="description">Description</label>
                      <Field
                        name="description"
                        className="description form-control"
                        id="description"
                        component="textarea"
                        placeholder="Description"
                      ></Field>
                    </div>
                    <input
                      type="submit"
                      value="Upload Now"
                      className="submit"
                      name="submit"
                    />
                  </form>
                )}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <ProfileAside />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps, {
  uploadPost,
  showSuccessAction,
  showFailureAction
})(PostUpload);
