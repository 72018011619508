import React, { Component } from "react";
import ProfileAside from "./ProfileAside.js";
import PlaylistList from "./PlaylistList";
class ProfilePlaylist extends Component {
  render() {
    return (
      <div className="author-contents">
        <div className="row">
          <div className="col-sm-8">
            <div className="about-author">
              <div className="btn-container">
                <select className="selectpicker">
                  <option>Newest</option>
                  <option>Older</option>
                  <option>Last 8 days</option>
                </select>
                <div className="layouts pull-right">
                  <button className="style-grid">
                    <i className="fa fa-th"></i>
                  </button>
                  <button className="style-list active">
                    <i className="fa fa-list"></i>
                  </button>
                </div>
              </div>
              <PlaylistList />
            </div>
          </div>
          <div className="col-sm-4">
            <ProfileAside />
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePlaylist;
