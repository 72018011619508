import React from "react";
import { Link } from "react-router-dom";
const SubmitVideoWidget = () => {
  return (
    <div className="widget widget_submit_video">
      <h3 className="widget-title">Submit Content</h3>
      <div className="widget-details">
        <p>
          Send videos of you shooting and landing fish, or even pictures after
          the catch for a chance to be featured through our network.
        </p>

        <div className="file-upload">
          <Link to="/submitVideo" className="btn upload-btn">
            Upload your video
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SubmitVideoWidget;
