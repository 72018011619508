import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux'
import classnames from "classnames";
import { registerUser } from "../../actions/authActions";
import { useNavigate  } from "react-router-dom";

export default function Register() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const errors = useSelector(state =>state.errors);
  const [formState, setFormState] = useState({ firstName: '', lastName: '', phone: '', email: '', password: '', password2: '', visible: ''});
  useEffect(() => {
    if (auth.isAuthenticated) { 
      navigate("/profile/home");
    }
  })

  function onChange(e){
    let newUserObject = Object.assign({}, formState, {
      [e.target.id]: e.target.value
    })
    setFormState(newUserObject)
  };
 
  function onSubmit(e){
    e.preventDefault();
    const userData = {
      firstName: formState.firstName,
      lastName: formState.lastName,
      phone: formState.phone,
      email: formState.email,
      password: formState.password,
      password2: formState.password2,
    };
    console.log("user Data: ", userData);
    dispatch(registerUser(userData)); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

  return (
    <section className="form-elements">
      <div className="section-padding">
        <div className="container">
          <div className="left-panel">
            <h2 className="section-title">Register Now</h2>
            <form
              className="register-form"
              noValidate
              onSubmit={onSubmit}
            >
              <p className="form-input">
                <label htmlFor="firstName">First Name</label>
                <input
                  onChange={onChange}
                  value={formState.firstName}
                  error={errors.firstName}
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  className={classnames("form-control", {
                    invalid: errors.firstName
                  })}
                  required
                />
                <span className="red-text">{errors.name}</span>
              </p>
              <p className="form-input">
                <label htmlFor="lastName">Last Name</label>
                <input
                  onChange={onChange}
                  value={formState.lastName}
                  error={errors.lastName}
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  className={classnames("form-control", {
                    invalid: errors.lastName
                  })}
                  required
                />
                <span className="red-text">{errors.name}</span>
              </p>

              <p className="form-input">
                <label htmlFor="password">Password</label>
                <input
                  onChange={onChange}
                  value={formState.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  placeholder="Password"
                  className={classnames("form-control", {
                    invalid: errors.password
                  })}
                  required
                />
                <span className="red-text">{errors.password}</span>
              </p>
              <p className="form-input">
                <label htmlFor="password2">Confirm Password</label>
                <input
                  onChange={onChange}
                  value={formState.password2}
                  error={errors.password2}
                  id="password2"
                  type="password"
                  placeholder="Confirm Password"
                  className={classnames("form-control", {
                    invalid: errors.password2
                  })}
                />
                <span className="red-text">{errors.password2}</span>
              </p>
              <p className="form-input">
                <label htmlFor="email">Email</label>
                <input
                  onChange={onChange}
                  value={formState.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  placeholder="Email"
                  className={classnames("form-control", {
                    invalid: errors.email
                  })}
                />
                <span className="red-text">{errors.email}</span>
              </p>
              <p className="form-input">
                <label htmlFor="phone">Phone</label>
                <input
                  onChange={onChange}
                  value={formState.phone}
                  error={errors.phone}
                  id="phone"
                  type="phone"
                  placeholder="555-555-5555"
                  className={classnames("form-control", {
                    invalid: errors.phone
                  })}
                />
                <span className="red-text">{errors.email}</span>
              </p>
              {formState.visible.captacha && this.renderCaptcha()}
              {formState.visible.captacha && this.renderCaptchaTextBox()}
              <p className="checkbox">
                <input
                  name="uelaCheck"
                  type="checkbox"
                  className="rememberme pull-left"
                  value="uelaCheck"
                />
                I agree the terms and conditions
              </p>
              <p className="submit-input">
                <input
                  type="submit"
                  className="btn"
                  name="signup-form-submit"
                  value="Register Now"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
