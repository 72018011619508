import React, { Component } from "react";

class SearchWidget extends Component {
  render() {
    return (
      <div className="widget widget_search">
        <div className="widget-details">
          <form method="get" className="search-form" action="#">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              name="search"
              title="Search.."
              required=""
            ></input>
            <input type="submit" className="form-control" name="submit"></input>
          </form>
        </div>
      </div>
    );
  }
}

export default SearchWidget;
