import React from 'react';
import instagram1 from '../../images/sidebar/ins/1.jpg';
import instagram2 from '../../images/sidebar/ins/2.jpg';
import instagram3 from '../../images/sidebar/ins/3.jpg';
import instagram4 from '../../images/sidebar/ins/4.jpg';
import instagram5 from '../../images/sidebar/ins/5.jpg';
import instagram6 from '../../images/sidebar/ins/6.jpg';

const InstagramWidget = () => (
  <div className="widget widget_instagram_feed">
    <h3 className="widget-title">Instagram Posts</h3>
    <div className="widget-details">
      <a href="/">
        <img src={instagram1} alt="Instagram 1" />
      </a>
      <a href="/">
        <img src={instagram2} alt="Instagram 2" />
      </a>
      <a href="/">
        <img src={instagram3} alt="Instagram 3" />
      </a>
      <a href="/">
        <img src={instagram4} alt="Instagram 4" />
      </a>
      <a href="/">
        <img src={instagram5} alt="Instagram 5" />
      </a>
      <a href="/">
        <img src={instagram6} alt="Instagram 6" />
      </a>
    </div>
  </div>
);

export default InstagramWidget;
