import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import VideoPost from './VideoPost';
import { getAllVideos } from '../../actions/postActions';
import {
  showSuccessAction,
  showFailureAction,
} from '../../actions/toastActions';

const Videos = (props) => {
  const videos = useSelector((state) => state.posts.videos);
  // const auth = useSelector(state => state.auth);
  useEffect(() => {
    props.getAllVideos()
      .then((res) => {
      })
      .catch((error) => {});
  }, [props]);
  console.log(videos);
  const showVideos = () => {
    const videosArray = Object.values(videos);
    return videosArray.map((post, i) => {
      const formattedVideo = {
        format: 'type-post',
        title: post.title,
        videoUploadDate: '3 days',
        type: 'postprofilevideo',
        commentCount: 0,
        viewCount: 0,
        tags: post.tags,
        postDescriptionShort: post.description,
        thumbnailImageHref: '',
        mediaPost: true,
        postPlaylist: true,
        author: post.author,
        youtube_id: post.youtube_id,
        id: post._id,
      };
      return <VideoPost key={i} post={formattedVideo} />;
    });
  };

  return (
    <section className="video-contents 3-column tag-sorting">
      <div className="section-padding">
        <div className="container">
          <h2 className="section-title">All Videos</h2>
          <div className="row">{showVideos()}</div>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getAllVideos,
  showSuccessAction,
  showFailureAction,
})(Videos);
