import React from "react";
const StayConnectedWidget = () => {
  const state = {
    facebookUrl: "https://www.facebook.com/spearchannel",
    instagramUrl: "https://www.instagram.com/spearchannel"
  };
  return (
    <div className="widget widget_social">
      <h3 className="widget-title">Stay Connected</h3>
      <div className="widget-details">
        <ul>
          <li>
            <a className="facebook" href={state.facebookUrl}>
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a className="instagram" href={state.instagramUrl}>
              <i className="fa fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StayConnectedWidget;
