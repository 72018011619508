import React from "react";

const VideoPostBody = props => {
  let { post } = props;

  if (Object.keys(post).length === 0 && post.constructor === Object) {
    post.author = {nmame: ''}
  }
  function displayDate(dateStr) {
    let months;
    let now;
    if (dateStr) {
      months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      now = new Date(dateStr);
      return (months[now.getMonth()] + ' ' + now.getDate() + ' ' + now.getFullYear());
    }
    return '';
  }

  return (
    <article className="post type-post format-standard">
      <div className="entry-content">
        <div className="entry-meta">
          <span>
            <time dateTime="2017-02-23">{displayDate(post.creation_date)}</time>
          </span>
          <span className="category tag">
            <a href="/">tags</a>
          </span>
        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">
            {post.title}
          </a>
        </h2>

        <div className="author-meta">
          <div className="col-xs-7">
            <div className="left-side media">
              <div className="author-avatar media-left">
                <img src={process.env.PUBLIC_URL + 'assets/img/profile_picture_default.png'} alt="Avatar"></img>
              </div>
              <div className="author-details media-body">
                <h3 className="name">
                  <a href="/">{post.author.name}</a>
                </h3>
                <button className="follow-btn">Follow</button>
                <span className="follow-counter">
                  <span className="count">3099</span> followers
                </span>
              </div>
            </div>
          </div>

          <div className="col-xs-5">
            <div className="right-side">
              <span className="view-counter">
                <span className="count">121,204</span> Views
              </span>
              <span className="like-count">
                <i className="fa fa-thumbs-o-up"></i>
                <span className="count">3099</span>
              </span>
              <span className="dislike-count">
                <i className="fa fa-thumbs-o-down"></i>
                <span className="count">789</span>
              </span>
            </div>
          </div>
        </div>

        <p>
          {post.description}
        </p>
      </div>

      <div className="post-bottom">
        <div className="tags pull-left">
          <a href="/">Spearfishing </a>
          <a href="/">Hawaii</a>
          <a href="/">Bluewater</a>
        </div>

        <div className="post-social pull-right">
          <button className="share-btn">
            Share <i className="fa fa-share-alt"></i>
          </button>
          <div className="btn-hover">
            <a className="facebook" href="/">
              <i className="fa fa-facebook-square"></i>{" "}
              <span className="count">09</span>
            </a>
            <a className="pinterest" href="/">
              <i className="fa fa-pinterest"></i> <span className="count">09</span>
            </a>
            <a className="twitter" href="/">
              <i className="fa fa-twitter-square"></i> <span className="count">09</span>
            </a>
            <a className="google" href="/">
              <i className="fa fa-google-plus-square"></i>{" "}
              <span className="count">09</span>
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};

export default VideoPostBody;
