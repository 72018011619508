import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux'
import classnames from "classnames";
import { loginUser } from "../../actions/authActions";
import { useNavigate  } from "react-router-dom";


export default function Login(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const errors = useSelector(state =>state.errors);
  const [formState, setFormState] = useState({ email: '', password: ''});
  useEffect(() => {
    if (auth.isAuthenticated) { 
      navigate("/profile/home");
    }
  })

  function onChange(e){
    let newUserObject = Object.assign({}, formState, {
      [e.target.id]: e.target.value
    })
    setFormState(newUserObject)
  };
 
  function onSubmit(e){
    e.preventDefault();
    const userData = {
      email: formState.email,
      password: formState.password
    };
    console.log("user Data: ", userData);
    dispatch(loginUser(userData)); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

    return (
      <div className="container">
        <section className="form-elements style-2">
          <div className="section-padding">
            <div className="right-panel">
              <h4>Have an account? Sign in</h4>
              <form
                className="sign-in-form"
                id="sign-in-form"
                noValidate
                onSubmit={onSubmit}
              >
                <p className="form-input name">
                  <label htmlFor="email">Email</label>
                  <input
                    onChange={onChange}
                    value={formState.email}
                    error={errors.email}
                    id="email"
                    type="text"
                    className={classnames("input form-control", {
                      invalid: errors.email || errors.emailnotfound
                    })}
                  />

                  <span className="red-text">
                    {errors.email}
                    {errors.emailnotfound}
                  </span>
                </p>
                <p className="form-input pswd">
                  <label htmlFor="password">Password</label>
                  <input
                    onChange={onChange}
                    value={formState.password}
                    error={errors.password}
                    id="password"
                    type="password"
                    placeholder="Password"
                    className={classnames("input form-control", {
                      invalid: errors.password || errors.passwordincorrect
                    })}
                  />
                  <span className="red-text">
                    {errors.password}
                    {errors.passwordincorrect}
                  </span>
                </p>
                <p className="submit-input">
                  <input
                    type="submit"
                    name="wp-submit"
                    id="wp-submit"
                    className="btn"
                    value="Log In Now"
                  />
                </p>
                <p className="checkbox">
                  <input
                    name="rememberme"
                    type="checkbox"
                    className="rememberme"
                    value="Remember Me"
                  />
                  Keep Me Signed in
                  <a href="/" className="pull-right" title="Keep Me Signed in">
                    {" "}
                    Forgot password?
                  </a>
                </p>
                <p className="form-input">
                  <span className="alt-methods">
                    <span>Or Sign in using</span>
                    <a className="facebook" href="/">
                      <i className="fa fa-facebook-official" />
                    </a>
                    <a className="twitter" href="/">
                      <i className="fa fa-twitter" />
                    </a>
                    <a className="google" href="/">
                      <i className="fa fa-google-plus" />
                    </a>
                  </span>
                </p>
                P
              </form>
            </div>
            <span className="bottom-text">
              Don’t Have an Account? <a href="signup.html">Sign Up</a>
            </span>
          </div>
        </section>
      </div>
    );

}