import React from "react";

const MagazineComments = () => {
  return (
    <div role="tabpanel" class="tab-pane" id="comments">
      <div class="comments">
        <ol class="comment-list">
          <li class="comment parent media">
            <div class="comment-item">
              <div class="author-avatar media-left">
                <img src="images/blog/au1.jpg" alt="Comment Authors"></img>
              </div>
              <div class="comment-body media-body">
                <div class="comment-metadata">
                  <span class="name">
                    <a href="/">Bob Baker</a>
                  </span>
                  <a class="btn reply pull-right" href="/">
                    Reply
                  </a>
                  <span class="time">
                    <time datetime="2017-02-09 21:00">
                      Feb 09, 2017 at 21:37
                    </time>{" "}
                  </span>
                </div>
                <p class="description">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident.
                </p>
              </div>
            </div>

            <ol class="children">
              <li class="comment media">
                <div class="comment-item">
                  <div class="author-avatar media-left">
                    <img src="images/blog/au2.jpg" alt="Comment Authors"></img>
                  </div>
                  <div class="comment-body media-body">
                    <div class="comment-metadata">
                      <span class="name">
                        <a href="/">Tony Hawk</a>
                      </span>
                      <a class="btn reply pull-right" href="/">
                        Reply
                      </a>
                      <span class="time">
                        <time datetime="2017-02-09 21:00">
                          Feb 09, 2017 at 21:37{" "}
                        </time>{" "}
                      </span>
                    </div>
                    <p class="description">
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident.
                    </p>
                  </div>
                </div>
              </li>
            </ol>
          </li>

          <li class="comment parent media">
            <div class="comment-item">
              <div class="author-avatar media-left">
                <img src="images/blog/au3.jpg" alt="Comment Authors"></img>
              </div>
              <div class="comment-body media-body">
                <div class="comment-metadata">
                  <span class="name">
                    <a href="/">Nick Watkins</a>
                  </span>
                  <a class="btn reply pull-right" href="/">
                    Reply
                  </a>
                  <span class="time">
                    <time datetime="2017-02-09 21:00">
                      Feb 09, 2017 at 21:37{" "}
                    </time>{" "}
                  </span>
                </div>
                <p class="description">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident.
                </p>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};
const MagazineTabContentSection = () => {
  const showComments = false;
  return (
    <div class="content-tabs">
      <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active">
          <a href="#casts" aria-controls="casts" role="tab" data-toggle="tab">
            Divers
          </a>
        </li>
        {showComments && (
          <li role="presentation">
            <a
              href="#comments"
              aria-controls="comments"
              role="tab"
              data-toggle="tab"
            >
              Comments
            </a>
          </li>
        )}
        <li role="presentation">
          <a href="#videos" aria-controls="videos" role="tab" data-toggle="tab">
            Videos
          </a>
        </li>
        <li role="presentation">
          <a href="#photes" aria-controls="photes" role="tab" data-toggle="tab">
            Photos
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div role="tabpanel" class="tab-pane active" id="casts">
          <div class="movie-casts">
            <div class="col-sm-3 col-xs-6">
              <h3 class="name">
                <a href="/">Daniel Hulme</a>
              </h3>
            </div>
            <div class="col-sm-3 col-xs-6">
              <h3 class="name">
                <a href="/">Perrin James</a>
              </h3>
            </div>
            <div class="col-sm-3 col-xs-6">
              <div class="crew-thumb"></div>
              <h3 class="name">
                <a href="/">Mark Healey</a>
              </h3>
            </div>
            <div class="col-sm-3 col-xs-6">
              <div class="crew-thumb"></div>
              <h3 class="name">
                <a href="/">Ryder DeVoe</a>
              </h3>
            </div>
          </div>
        </div>

        {showComments && <MagazineComments></MagazineComments>}

        <div role="tabpanel" class="tab-pane" id="videos">
          <div class="related-videos">
            <div class="col-xs-6">
              <div class="item-thumb">
                <a
                  href="https://www.youtube.com/watch?v=3ZuLmJZfDA0"
                  class="iframe play-video"
                >
                  <i class="fa fa-play-circle-o"></i>
                </a>
              </div>
            </div>

            <div class="col-xs-6">
              <div class="item-thumb">
                <a
                  href="https://www.youtube.com/watch?v=Ec7_ZmIrRfg"
                  class="iframe play-video"
                >
                  <i class="fa fa-play-circle-o"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div role="tabpanel" class="tab-pane" id="photes">
          <div class="related-photos">
            <div class="col-sm-4 col-xs-6">
              <div class="item-thumb">
                <a href="images/movie/lg1.jpg" class="image-popup">
                  <img
                    src="https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
                    alt="Thumb"
                  ></img>
                </a>
              </div>
            </div>
            <div class="col-sm-4 col-xs-6">
              <div class="item-thumb">
                <a href="images/movie/lg2.jpg" class="image-popup">
                  <img
                    src="https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
                    alt="Thumb"
                  ></img>
                </a>
              </div>
            </div>
            <div class="col-sm-4 col-xs-6">
              <div class="item-thumb">
                <a href="images/movie/lg3.jpg" class="image-popup">
                  <img
                    src="https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
                    alt="Thumb"
                  ></img>
                </a>
              </div>
            </div>
            <div class="col-sm-4 col-xs-6">
              <div class="item-thumb">
                <a href="images/movie/lg4.jpg" class="image-popup">
                  <img
                    src="https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
                    alt="Thumb"
                  ></img>
                </a>
              </div>
            </div>
            <div class="col-sm-4 col-xs-6">
              <div class="item-thumb">
                <a href="images/movie/lg5.jpg" class="image-popup">
                  <img
                    src="https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
                    alt="Thumb"
                  ></img>
                </a>
              </div>
            </div>
            <div class="col-sm-4 col-xs-6">
              <div class="item-thumb">
                <a href="images/movie/lg6.jpg" class="image-popup">
                  <img
                    src="https://spearchannel-content.s3.amazonaws.com/ryder_ahi_hero_3.jpg"
                    alt="Thumb"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MagazineTabContentSection;
