import React, { Component } from "react";
import HomePageCollage from "./HomePageCollage.js";
import VideoPlaylistsSection from "./VideoPlaylistsSection.js";
import HomePageSection from "./HomePageSection.js";

class HomePage extends Component {
  render() {
    return (
      <div>
        <HomePageCollage />
        <VideoPlaylistsSection />
        <HomePageSection />
      </div>
    );
  }
}

export default HomePage;
