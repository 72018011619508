/*
 *   Posts Reducer
 */

import { RECEIVE_ALL_POSTS, SET_MY_POSTS } from '../../actions/types';

export default function (posts = [], action) {
  switch (action.type) {
    case RECEIVE_ALL_POSTS:
      return action.payload;
    case SET_MY_POSTS:
      return action.payload;
    default:
      return posts;
  }
}
