import React from "react";

const PostComments = () => {
  return (
    <div className="comments">
      <h2 className="section-title">09 Comments</h2>
      <ol className="comment-list">
        <li className="comment parent media">
          <div className="comment-item">
            <div className="author-avatar media-left">
              <img src="images/blog/au1.jpg" alt="Comment Authors" />
            </div>
            <div className="comment-body media-body">
              <div className="comment-metadata">
                <span className="name">
                  <a href="/">Diver Girl</a>
                </span>
                <a className="btn reply pull-right" href="/">
                  Reply
                </a>
                <span className="time">
                  <time dateTime="2017-02-09 21:00">Feb 09, 2017 at 21:37</time>{" "}
                </span>
              </div>
              <p className="description">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident.
              </p>
            </div>
          </div>

          <ol className="children">
            <li className="comment media">
              <div className="comment-item">
                <div className="author-avatar media-left">
                  <img src="images/blog/au2.jpg" alt="Comment Authors" />
                </div>
                <div className="comment-body media-body">
                  <div className="comment-metadata">
                    <span className="name">
                      <a href="/">Diver GirlOther</a>
                    </span>
                    <a className="btn reply pull-right" href="/">
                      Reply
                    </a>
                    <span className="time">
                      <time dateTime="2017-02-09 21:00">
                        Feb 09, 2017 at 21:37{" "}
                      </time>{" "}
                    </span>
                  </div>
                  <p className="description">
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident.
                  </p>
                </div>
              </div>
            </li>
          </ol>
        </li>

        <li className="comment parent media">
          <div className="comment-item">
            <div className="author-avatar media-left">
              <img src="images/blog/au3.jpg" alt="Comment Authors" />
            </div>
            <div className="comment-body media-body">
              <div className="comment-metadata">
                <span className="name">
                  <a href="/">Nick Watkins</a>
                </span>
                <a className="btn reply pull-right" href="/">
                  Reply
                </a>
                <span className="time">
                  <time datetime="2017-02-09 21:00">
                    Feb 09, 2017 at 21:37{" "}
                  </time>{" "}
                </span>
              </div>
              <p className="description">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident.
              </p>
            </div>
          </div>
        </li>
      </ol>

      <div className="respond">
        <h2 className="title">Leave a Comment</h2>
        <form action="#" method="post" className="comment-form">
          <input
            className="form-control"
            id="name"
            name="name"
            type="text"
            placeholder="Name*"
            required
          />
          <input
            className="form-control"
            id="email"
            name="email"
            type="email"
            placeholder="Email*"
            required
          />
          <input
            className="form-control"
            id="url"
            name="website"
            type="url"
            placeholder="Website"
          />
          <textarea
            id="comment"
            className="form-control"
            name="comment"
            placeholder="Comment*"
            rows="8"
          ></textarea>
          <input className="btn" type="submit" value="Post Comment" />
        </form>
      </div>
    </div>
  );
};

export default PostComments;
