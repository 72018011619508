import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCountryByCode } from "../../constants/countryCodes.js";
import ProfileAside from "./ProfileAside.js";

class ProfileDetails extends Component {
  render() {
    const currentProfile = this.props.auth.user;
    const showExtra = false;
    const country = getCountryByCode(currentProfile.location);
    const contryName = country ? country.name : "";
    console.log("createdAt: ", currentProfile.createdAt);
    const dateUnformatted = new Date(currentProfile.createdAt);
    const dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    const dateFormatted = dateUnformatted.toLocaleDateString(
      "en-US",
      dateOptions
    );
    let user = {
      details: currentProfile.description,
      subscribers: 0,
      joined: dateFormatted,
      views: "0",
      country: contryName,
      website: currentProfile.website,
      facebook: "",
      instagram: ""
    };

    return (
      <div className="author-contents">
        <div className="row">
          <div className="col-sm-8">
            <div className="about-author">
              <h4>Description</h4>
              <p>{user.details}</p>

              <div className="author-details">
                <h4>Details</h4>

                <div className="widget widget_author_details">
                  <ul>
                    {showExtra && (
                      <li>
                        <span className="meta-id">Subscribers:</span>{" "}
                        {user.subscribers}
                      </li>
                    )}
                    <li>
                      <span className="meta-id">Joined:</span> {user.joined}
                    </li>
                    {showExtra && (
                      <li>
                        <span className="meta-id">Views:</span> {user.views}
                      </li>
                    )}
                    <li>
                      <span className="meta-id">Country:</span> {user.country}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-links">
                <h4>Links</h4>

                <ul className="links">
                  <li>
                    <a href="/">
                      <i className="fa fa-globe"></i> Official Website
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-facebook-square"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-instagram"></i> Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <ProfileAside />
          </div>
        </div>
      </div>
    );
  }
}

ProfileDetails.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(ProfileDetails);
