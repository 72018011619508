import React from "react";

const SocialWidget = () => {
  return (
    <div className="widget widget_social">
      <h3 className="widget-title">Stay Connected</h3>
      <div className="widget-details">
        <ul>
          <li>
            <a className="twitter" href="http://www.twitter.com">
              <i className="fa fa-twitter"></i>
              <span className="count">217K</span> Twitter Followers
            </a>
          </li>
          <li>
            <a className="facebook" href="http://www.facebook.com">
              <i className="fa fa-facebook"></i>
              <span className="count">118k</span> Facebook Fans
            </a>
          </li>
          <li>
            <a className="vimeo" href="http://www.vimeo.com">
              <i className="fa fa-vimeo"></i>
              <span className="count">90k</span> Google Plus Fans
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SocialWidget;
