import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class ProfileNavigation extends Component {
  render() {
    return (
      <nav className="author-page-links">
        <NavLink activeclassname="active" to="/profile/home">
          Home
        </NavLink>
        <NavLink activeclassname="active" to="/profile/details">
          About
        </NavLink>
        <NavLink activeclassname="active" to="/profile/videos">
          Videos
        </NavLink>
        <NavLink activeclassname="active" to="/profile/upload">
          Upload
        </NavLink>
        <NavLink activeclassname="active" to="/profile/edit">
          Edit
        </NavLink>
      </nav>
    );
  }
}

export default ProfileNavigation;
