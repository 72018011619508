/*
 *   CurrentPost Reducer
 */

import { SET_CURRENT_POST } from "../actions/types";

export default function(currentPost = {}, action) {
  switch (action.type) {
    case SET_CURRENT_POST:
      return action.payload;

    default:
      return currentPost;
  }
}
