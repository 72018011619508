import React from 'react';

const VideoBannerPost = (props) => (
  <div className="item">
    <article className="post type-post">
      <div className="entry-thumbnail">
        <img
          src="https://i.vimeocdn.com/video/604253103.jpg?mw=1280&mh=1080"
          alt="Slider"
        />
        <a className="play-video iframe" href="https://vimeo.com/192598510">
          <i className="fa fa-play" />
        </a>
      </div>
    </article>
  </div>
);

const VideoBanner = (props) => {
  const { post } = props;

  const thumbnail_img = `https://i.ytimg.com/vi/${post.youtube_id}/hqdefault.jpg`;
  const video_url = `https://youtube.com/watch?v=${post.youtube_id}`;

  console.log();
  return (
    <section className="banner-section video-banner">
      <div id="banner-slider-03" className="banner-slider-03 carousel slide">
        <div className="carousel-inner">
          <div className="item active">
            <article className="post type-post">
              <div className="entry-thumbnail">
                <img
                  src={thumbnail_img}
                  alt="Slider"
                />
                <a
                  className="play-video iframe"
                  href={video_url}
                >
                  <i className="fa fa-play" />
                </a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoBanner;
