import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

class TopBar extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    let authSection;
    if (!isAuthenticated) {
      authSection = (
        <div className="top-sitemap text-left">
          <span>Welcome</span>
          <span>
            {" "}
            <Link to="/login">
              <i className="fa fa-lock"></i> Sign In
            </Link>
          </span>
          <span>
            <Link to="/register">
              <i className="fa fa-user"></i> Register
            </Link>
          </span>
        </div>
      );
    } else {
      authSection = (
        <div className="top-sitemap text-left">
          <span>Welcome Back {user.firstName.split(" ")[0]}!</span>
          <span>
            <a href="/" onClick={this.onLogoutClick} className="hoverable">
              Logout
            </a>{" "}
          </span>
        </div>
      );
    }
    return (
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4">{authSection}</div>
          </div>
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(TopBar);
