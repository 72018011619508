/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import ProfileAside from './ProfileAside';
import Post from '../posts/Post';
import {
  showSuccessAction,
  showFailureAction,
} from '../../actions/toastActions';
import { getPostByAuthorId } from '../../actions/postActions';

const ProfileVideos = (props) => {
  const userId = props.auth.user.id;
  const beta = false;

  useEffect(
    () => {
      props
        .getPostByAuthorId(userId)
        .then(() => {
        })
        .catch(() => {
        });
    },
    [props.getPostByAuthorId],
  );
  const posts = useSelector((state) => state.posts.posts);
  const showPosts = () => posts.map((post) => {
    const formattedPost = {
      format: 'type-post',
      title: post.title,
      videoUploadDate: '3 days',
      type: 'postprofilevideo',
      commentCount: 0,
      viewCount: 0,
      postDescriptionShort: post.description,
      thumbnailImageHref: '',
      mediaPost: true,
      postPlaylist: true,
      youtube_id: post.youtube_id,
      id: post.id,
    };
    return <Post key={post.id} post={formattedPost} />;
  });

  return (
    <div className="author-contents">
      <div className="row">
        <div className="col-sm-8">
          <div className="about-author">
            <div className="btn-container">
              {beta && (
              <select className="selectpicker">
                <option>Newest</option>
                <option>Older</option>
                <option>Last 7 days</option>
              </select>
              ) }
              <div className="layouts pull-right">
                <button key="button1" className="style-grid active">
                  <i className="fa fa-th" />
                </button>
                <button key="button2" className="style-list">
                  <i className="fa fa-list" />
                </button>
              </div>
            </div>

            <div className="author-videos">
              {showPosts()}
              <div className="btn-container text-center">
                <a href="/" className="btn load-more">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <ProfileAside />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getPostByAuthorId,
  showSuccessAction,
  showFailureAction,
})(ProfileVideos);
