/*
 *  Latest Videos Reducer
 */

import { RECEIVE_LATEST_VIDEOS } from "../../actions/types";

export default function(latestVideos = {}, action) {
  switch (action.type) {
    case RECEIVE_LATEST_VIDEOS:
      return action.payload;

    default:
      return latestVideos;
  }
}
