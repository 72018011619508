import React from "react";

const PostPagination = () => {
  return (
    <div className="post-navigation">
      <article className="post type-post media">
        <div className="entry-thumbnail media-left">
          <img
            src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
            alt="Post Thumbnail"
          />
        </div>
        <div className="entry-content media-body">
          <h2 className="entry-title">
            <a href="/">Spearfishing Africa </a>
          </h2>
          <div className="entry-meta">
            <span>
              <i className="fa fa-calendar"></i>{" "}
              <time datetime="2017-09-02">09 Feb, 2017</time>
            </span>
            <span>
              <i className="fa fa-comment-o"></i>{" "}
              <span className="count">32</span>
            </span>
          </div>
        </div>
      </article>

      <article className="post type-post media">
        <div className="entry-thumbnail media-left">
          <img
            src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
            alt="Post Thumbnail"
          />
        </div>
        <div className="entry-content media-body">
          <h2 className="entry-title">
            <a href="/">Spearfishing Africa</a>
          </h2>
          <div className="entry-meta">
            <span>
              <i className="fa fa-calendar"></i>{" "}
              <time datetime="2017-09-02">09 Feb, 2017</time>
            </span>
            <span>
              <i className="fa fa-comment-o"></i>{" "}
              <span className="count">32</span>
            </span>
          </div>
        </div>
      </article>
    </div>
  );
};

export default PostPagination;
