import React, { Component } from "react";
import CheckOutAlsoWidget from "../widgets/CheckOutAlsoWidget";
import StayConnectedWidget from "../widgets/StayConnectedWidget";
class MagazineSideRail extends Component {
  render() {
    return (
      <aside className="sidebar">
        <div className="widget widget_facebbok_feed">
          <div className="widget-details">
            <div
              className="fb-page"
              data-href="https://www.facebook.com/HawaiiSkinDiver/"
              data-tabs=""
              data-width="320"
              data-height="480"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
            >
              <blockquote
                cite="https://www.facebook.com/HawaiiSkinDiver/"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/HawaiiSkinDiver/">
                  Hawaii Skin Diver
                </a>
              </blockquote>
            </div>
          </div>
        </div>
        <CheckOutAlsoWidget></CheckOutAlsoWidget>
        <StayConnectedWidget></StayConnectedWidget>
      </aside>
    );
  }
}
export default MagazineSideRail;
