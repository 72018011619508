/*
 *  Top Video Playlists Reducer
 */

import { RECEIVE_TOP_VIDEO_PLAYLISTS } from "../../actions/types";

export default function(topVideoPlaylists = {}, action) {
  switch (action.type) {
    case RECEIVE_TOP_VIDEO_PLAYLISTS:
      return action.payload;

    default:
      return topVideoPlaylists;
  }
}
