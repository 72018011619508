import React, { Component } from "react";
import DiverThumbnail from "./DiverThumbnail";
class ProfileAside extends Component {
  render() {
    return (
      <aside className="sidebar">
        <div className="widget widget_featured_authors">
          <h3 className="widget-title">Featured divers</h3>
          <div className="widget-details">
            <DiverThumbnail />
            <DiverThumbnail />
            <DiverThumbnail />
          </div>
        </div>

        <div className="widget widget_related_authors">
          <h3 className="widget-title">Related divers</h3>
          <div className="widget-details">
            <DiverThumbnail />
            <DiverThumbnail />
            <DiverThumbnail />
          </div>
        </div>
      </aside>
    );
  }
}

export default ProfileAside;
