import React, { Component } from "react";
import TopBar from "./TopBar.js";
import HeaderMiddle from "./HeaderMiddle";
import HeaderNavigation from "./HeaderNavigation";

class Header extends Component {
  render() {
    return (
      <header className="header">
        <TopBar />
        <HeaderMiddle />
        <HeaderNavigation />
      </header>
    );
  }
}

export default Header;
