import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProfileAside from "./ProfileAside";
import classnames from "classnames";
import { updateUser } from "../../actions/userActions";
import { countryList } from "../../constants/countryCodes";

class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      location: user.location ? user.location : "",
      website: user.website ? user.website : "",
      instagram: user.instagram ? user.instagram : "",
      youtube: user.youtube ? user.youtube : "",
      description: user.description ? user.description : "",
      errors: {}
    };
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const userId = this.props.auth.user.id;
    const userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      location: this.state.location,
      website: this.state.website,
      instagram: this.state.instagram,
      youtube: this.state.youtube,
      description: this.state.description
    };
    this.props.updateUser(userId, userData);
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="author-contents">
        <div className="row">
          <div className="col-sm-8">
            <div className="about-author">
              <div className="upload-video">
                <form
                  className="upload-form"
                  noValidate
                  onSubmit={this.onSubmit}
                >
                  <p className="form-element">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.firstName}
                      error={errors.firstName}
                      id="firstName"
                      type="text"
                      className={classnames("firstName form-control", {
                        invalid: errors.firstName
                      })}
                      placeholder="First Name"
                    />
                    <span className="red-text">{errors.firstName}</span>
                  </p>
                  <p className="form-element">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.lastName}
                      error={errors.lastName}
                      id="lastName"
                      type="text"
                      className={classnames("lastName form-control", {
                        invalid: errors.lastName
                      })}
                      placeholder="Last name"
                    />
                    <span className="red-text">{errors.lastName}</span>
                  </p>
                  <p className="form-element">
                    <label htmlFor="category">Location</label>
                    <select
                      onChange={this.onChange}
                      value={this.state.location}
                      error={errors.location}
                      id="location"
                      name="location"
                      className={classnames("location form-control", {
                        invalid: errors.location
                      })}
                    >
                      <option>Select a country</option>
                      {countryList.map((country, i) => {
                        return (
                          <option key={i} value={country.code}>
                            {country.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="red-text">{errors.location}</span>
                  </p>
                  <p className="form-element">
                    <label htmlFor="website">Website</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.website}
                      error={errors.website}
                      id="website"
                      type="text"
                      className={classnames("website form-control", {
                        invalid: errors.website
                      })}
                      placeholder="Your site"
                    />
                    <span className="red-text">{errors.website}</span>
                  </p>

                  <p className="form-element">
                    <label htmlFor="instagram">Instagram</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.instagram}
                      error={errors.instagram}
                      id="instagram"
                      type="text"
                      className={classnames("instagram form-control", {
                        invalid: errors.instagram
                      })}
                      placeholder="Instagram handle"
                    />
                    <span className="red-text">{errors.instagram}</span>
                  </p>
                  <p className="form-element">
                    <label htmlFor="youtube">Youtube</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.youtube}
                      error={errors.youtube}
                      id="youtube"
                      type="text"
                      className={classnames("youtube form-control", {
                        invalid: errors.youtube
                      })}
                      placeholder="Youtube channel"
                    />
                    <span className="red-text">{errors.youtube}</span>
                  </p>
                  <p className="form-element file-type">
                    <label htmlFor="description">Description</label>
                    <textarea
                      onChange={this.onChange}
                      name="description"
                      className="description form-control"
                      id="description"
                      placeholder="Description"
                      value={this.state.description}
                    ></textarea>
                  </p>
                  <input
                    type="submit"
                    value="Update profile"
                    className="submit"
                    name="submit"
                  />
                </form>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <ProfileAside />
          </div>
        </div>
      </div>
    );
  }
}

ProfileEdit.propTypes = {
  updateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps, { updateUser })(ProfileEdit);
