/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCurrentPost } from '../../actions/postActions';

const VideoPost = (props) => {
  const { post } = props;

  const thumbnailImg = `https://i.ytimg.com/vi/${post.youtube_id}/hqdefault.jpg`;
  console.log('post: ', post);
  return (
    <div className="col-md-4">
      <article className="post type-post">
        <div className="entry-thumbnail">
          <Link to={`/videopost/${post.id}`}>
            <img
              src={thumbnailImg}
              alt="Item Thumbnail"
            />
          </Link>
        </div>
        <div className="entry-content">
          <span className="category tag">
            <a href="/videos">{post.tags}</a>
          </span>
          <h3 className="entry-title">
            <Link to={`/post/${post.id}`}>{post.title}</Link>
          </h3>
          <div className="entry-meta">
            <span className="author">
              <a href="/">{post.author.name}</a>
            </span>
            <span>
              <i className="fa fa-clock-o" />
              {' '}
              <time dateTime="PT5M">
                {post.creation_date}
                s
              </time>
            </span>
          </div>
        </div>
      </article>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {
  setCurrentPost,
})(VideoPost);
